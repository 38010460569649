import { ProOrdersInfo } from "@/store/orders";
import { CallsRequestFilters } from "@/types/calls";
import { FeedbackRequestFilters } from "@/types/feedback";
import { Order, OrderRating } from "@/types/order";
import { handleError } from "@/utils/error";
import BaseService from "./base-service";

class OrdersService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async getProOrders(): Promise<Order[]> {
    try {
      return await this.get(`${this.getServiceContext}/`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async proAcceptRequest(
    orderId: string,
    callTime?: Date,
  ): Promise<Order> {
    try {
      return await this.update(`${this.getServiceContext}/${orderId}/accept`, {
        callTime,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async proDeclineRequest(orderId: string): Promise<Order> {
    try {
      return await this.get(`${this.getServiceContext}/${orderId}/decline`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getArtistCreatedCalls(): Promise<Order[]> {
    try {
      return await this.get(`${this.getServiceContext}/created/calls`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getArtistCreatedRequests(): Promise<Order[]> {
    try {
      return await this.get(`${this.getServiceContext}/created/feedbacks`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getArtistSubmittedPlaylists(query: string): Promise<Order[]> {
    try {
      return await this.get(
        `${this.getServiceContext}/submitted/playlists?${query}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getProInfo(): Promise<ProOrdersInfo> {
    try {
      return await this.get(`${this.getServiceContext}/pro-info`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getCallsRequests(
    filters: CallsRequestFilters,
    paginationQuery: { pageOffset: number; pageLength: number },
  ): Promise<Order[]> {
    try {
      return await this.post(`${this.getServiceContext}/calls-requests`, {
        filters,
        paginationQuery,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getFeedbackRequests(
    filters: FeedbackRequestFilters,
    paginationQuery: { pageOffset: number; pageLength: number },
    archived: boolean,
  ): Promise<Order[]> {
    try {
      return await this.post(`${this.getServiceContext}/feedback-requests`, {
        filters,
        paginationQuery,
        archived,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async rescheduleCall(orderId: string, callTime: Date): Promise<Order> {
    try {
      return await this.post(
        `${this.getServiceContext}/${orderId}/reschedule`,
        {
          callTime,
        },
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async contactSupport(
    orderId: string,
    message: string,
  ): Promise<Order> {
    try {
      return await this.post(`${this.getServiceContext}/${orderId}/contact`, {
        message,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async rateOrder(
    orderId: string,
    rating: OrderRating,
  ): Promise<Order | void> {
    try {
      return await this.post(
        `${this.getServiceContext}/${orderId}/rate`,
        rating,
      );
    } catch (error) {
      throw handleError(error);
    }
  }
}

export default OrdersService;

import OrdersService from "../services/orders-service";
import { Order, OrderRating } from "@/types/order";
import { handleError } from "@/utils/error";
import { defineStore } from "pinia";

export interface ProOrdersInfo {
  feedbackToGive: number;
  callRequests: number;
  earningToday: number;
  earnedTotal: number;
}

interface OrdersStore {
  artistOpenedOrder?: Order;
  artistCreatedCalls: Order[];
  artistCreatedRequests: Order[];
  dashboardOrdersLoaded: boolean;

  ordersLoading: boolean;
  orders: Order[];
  byRequestId: Record<string, Order>;

  proInfo: ProOrdersInfo;
}

const ordersService = new OrdersService("orders");
const ordersCollectionLoaded = ref(0);

export const useOrdersStore = defineStore("orders", {
  state: (): OrdersStore => ({
    artistOpenedOrder: undefined,
    artistCreatedCalls: [],
    artistCreatedRequests: [],
    dashboardOrdersLoaded: false,

    ordersLoading: false,
    orders: [],
    byRequestId: {},
    proInfo: {
      feedbackToGive: 0,
      callRequests: 0,
      earningToday: 0,
      earnedTotal: 0,
    },
  }),
  actions: {
    async getProRequests(): Promise<Order[]> {
      try {
        this.ordersLoading = true;
        this.orders = await ordersService.getProOrders();
        return this.orders as Order[];
      } catch (error) {
        throw handleError(error);
      } finally {
        this.ordersLoading = false;
      }
    },
    artistOpenOrder(order?: Order) {
      this.artistOpenedOrder = order;
    },
    async getArtistCreatedCalls(): Promise<Order[]> {
      if (this.artistCreatedCalls.length) {
        return this.artistCreatedCalls;
      }

      try {
        this.artistCreatedCalls = await ordersService.getArtistCreatedCalls();
        ordersCollectionLoaded.value++;
        if (ordersCollectionLoaded.value === 2)
          this.dashboardOrdersLoaded = true;
        return this.artistCreatedCalls;
      } catch (error) {
        throw handleError(error);
      }
    },
    async getArtistCreatedRequests(): Promise<Order[]> {
      if (this.artistCreatedRequests.length) {
        return this.artistCreatedRequests;
      }

      try {
        this.artistCreatedRequests =
          await ordersService.getArtistCreatedRequests();
        ordersCollectionLoaded.value++;
        if (ordersCollectionLoaded.value === 2)
          this.dashboardOrdersLoaded = true;
        return this.artistCreatedRequests;
      } catch (error) {
        throw handleError(error);
      }
    },
    async getProInfo(): Promise<any> {
      try {
        this.proInfo = await ordersService.getProInfo();
        return this.proInfo;
      } catch (error) {
        throw handleError(error);
      }
    },

    async rescheduleCall(orderId: string, callTime: Date): Promise<any> {
      try {
        const res = await ordersService.rescheduleCall(orderId, callTime);
        return res;
      } catch (error) {
        throw handleError(error);
      }
    },
    async contactSupport(orderId: string, message: string): Promise<any> {
      try {
        const res = await ordersService.contactSupport(orderId, message);
        return res;
      } catch (error) {
        throw handleError(error);
      }
    },
    async rateOrder(
      orderId: string,
      rating: OrderRating,
    ): Promise<Order | void> {
      const updatedOrder = await ordersService.rateOrder(orderId, rating);
      return updatedOrder;
    },
  },
});
